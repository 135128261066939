<template>
    <div
        class="verify-account-index-page"
        :style="{
            backgroundImage: 'url(' + $assets.auth.login.city + ')',
        }"
    >
        <div v-if="init" class="verify-success">
            <picture v-if="init.status" class="verify-success__image">
                <img :src="$assets.auth.recovery.success" alt="image" />
            </picture>
            <h2 class="verify-success__title">{{ init.title }}</h2>
            <p class="verify-success__subtitle">{{ init.message }}</p>
            <div class="verify-success__buttons">
                <ButtonGeneric
                    v-for="(button, index) in init.buttons"
                    :key="index"
                    class="verify-success__button"
                    :info="button"
                />
            </div>
            <div v-if="init.redirect" class="verify-success__countdown">
                <p class="verify-success__countdown-tittle">{{ init.redirect.text }}</p>
                <p class="verify-success__countdown-count">{{ countDown }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'

const Route = useRoute()
const Router = useRouter()
const { $lang, $assets } = useNuxtApp()

if (!Route.query.code) {
    throw createError({
        statusCode: 401,
        message: $lang.pages.verificarCuenta.request_failed,
    })
}

const countDown = ref(10)

const AuthStore = useAuthStore()

const result = AuthStore.VerificationResult

const init = {
    message: '',
    title: '',
    status: null as null | boolean,
    buttons: [] as { text: string; icon: string; link?: string; handler?: () => void }[],
    redirect: { link: '/', text: $lang.pages.verificarCuenta.redirect } as any,
}
if (result) {
    if (result.feedback === 'verified_account_success') {
        init.status = true

        init.title = $lang.pages.verificarCuenta.verified_success

        init.title = $lang.pages.verificarCuenta.enjoy

        init.buttons = [
            {
                icon: $assets.white.homeMenu,
                text: $lang.pages.verificarCuenta.go_home,
                link: '/',
            },
            {
                icon: $assets.white.user,
                text: $lang.pages.verificarCuenta.go_profile,
                link: `/${$lang.routes.dashboard}`,
            },
        ]
    } else {
        init.status = false

        init.redirect = null

        init.title = $lang.pages.verificarCuenta.error_on_validate

        init.message = result.data.code?.[0] ? $lang.pages.verificarCuenta.code_not_valid : ''

        if (AuthStore.IsAuth) {
            init.buttons = [
                {
                    icon: $assets.white.userHead,
                    text: $lang.pages.verificarCuenta.go_to_settings,
                    link: `/${$lang.routes.dashboard}/${$lang.routes.settings}`,
                },
            ]
        } else if (result.feedback === 'params_validation_failed') {
            init.redirect = null

            init.buttons = [
                {
                    icon: $assets.white.userHead,
                    text: $lang.pages.verificarCuenta.contact_support,
                    link: `/${$lang.routes.contact}`,
                },
                {
                    icon: $assets.white.logout,
                    text: $lang.pages.verificarCuenta.go_home,
                    link: '/',
                },
            ]
        }
    }
} else {
    init.message = $lang.pages.verificarCuenta.verify_error
    init.buttons = [
        {
            icon: $assets.white.user,
            text: $lang.pages.verificarCuenta.contact_support,
            link: `/${$lang.routes.contact}`,
        },
        {
            icon: $assets.white.homeMenu,
            text: $lang.pages.verificarCuenta.go_home,
            link: '/',
        },
    ]
}
onMounted(() => {
    if (init.redirect) {
        const timer = setInterval(() => {
            countDown.value--
            if (countDown.value === 0) {
                clearInterval(timer)

                Router.push({ path: init.redirect?.link })
            }
        }, 1000)
    }
})
</script>
<style lang="postcss" scoped>
.verify-account-index-page {
    @apply container bg-contain bg-center bg-no-repeat pb-5 lg:pb-10;

    .verify-success {
        @apply relative mx-auto mt-3 max-w-xl space-y-3 rounded-lg bg-white bg-opacity-95 p-5 xs:mt-20;
        &__image {
            @apply block h-40;
            img {
                @apply mx-auto h-full;
            }
        }
        &__title {
            @apply text-center text-3xl font-medium text-gray-800;
        }
        &__subtitle {
            @apply text-center text-xl text-gray-800;
        }
        &__buttons {
            @apply mb-5 space-y-3 md:flex md:flex-wrap md:justify-center md:space-x-5 md:space-y-0;
        }
        &__button {
            @apply bg-site-primary text-white lg:transition-colors lg:hover:bg-site-primary-hover;
        }
        &__countdown {
            @apply space-y-4 text-center;
        }
        &__countdown-title {
            @apply text-lg text-gray-800;
        }
        &__countdown-count {
            @apply text-6xl text-site-primary;
        }
    }
}
</style>
